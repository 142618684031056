<template>
  <div>
    <b-overlay :show="showOverlay" rounded="sm">
      <b-card style="max-width: 100%; width: 100%" class="mb-2">
        <b-tabs content-class="mt-3" pills>
          <b-tab :title="$t('COMMON.MAIN')" active>
            <b-card-body>
              <b-form-group
                id="field-name"
                label-cols-lg="2"
                :label="$t('COMMON.NAME')"
                label-for="field-name"
              >
                <b-form-input
                  id="field-name-input"
                  v-model="dto.name"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="field-description"
                label-cols-lg="2"
                :label="$t('COMMON.DESCRIPTION')"
                label-for="field-description-input"
              >
                <b-form-input
                  id="field-description-input"
                  v-model="dto.description"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="field-maxDeviceCount"
                label-cols-lg="2"
                :label="$t('TARIFFS.MAX_DEVICE_COUNT')"
                label-for="field-maxDeviceCount-input"
              >
                <b-form-input
                  id="field-maxDeviceCount-input"
                  v-model="dto.maxDeviceCount"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="field-maxRequestCount"
                label-cols-lg="2"
                :label="$t('TARIFFS.MAX_REQUEST_COUNT')"
                label-for="field-maxRequestCount-input"
              >
                <b-form-input
                  id="field-maxRequestCount-input"
                  v-model="dto.maxRequestCount"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="field-maxUserCount"
                label-cols-lg="2"
                :label="$t('TARIFFS.MAX_USER_COUNT')"
                label-for="field-maxUserCount-input"
              >
                <b-form-input
                  id="field-maxUserCount-input"
                  v-model="dto.maxUserCount"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="field-maxTeamCount"
                label-cols-lg="2"
                :label="$t('TARIFFS.MAX_MANAGED_TEAM_COUNT')"
                label-for="field-maxTeamCount-input"
              >
                <b-form-input
                  id="field-maxUserCount-input"
                  v-model="dto.maxManagedTeamCount"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="field-allowLogoCustomization"
                label-cols-lg="2"
                :label="$t('TARIFFS.ALLOW_LOGO_CUSTOMIZATION')"
                label-for="field-allowLogoCustomization-input"
              >
                <b-form-checkbox
                  id="checkbox-allowLogoCustomization-input"
                  v-model="dto.allowLogoCustomization"
                  name="checkbox-allowLogoCustomization"
                  class="pt-2"
                  switch
                  size="lg"
                >
                </b-form-checkbox>
              </b-form-group>

              <b-form-group
                id="field-allowIpFiltrating"
                label-cols-lg="2"
                :label="$t('TARIFFS.ALLOW_IP_FILTRATING')"
                label-for="field-allowIpFiltrating-input"
              >
                <b-form-checkbox
                  id="checkbox-allowIpFiltrating-input"
                  v-model="dto.allowIpFiltrating"
                  name="checkbox-allowIpFiltrating"
                  class="pt-2"
                  switch
                  size="lg"
                >
                </b-form-checkbox>
              </b-form-group>

              <b-form-group
                id="field-allowRequestLogs"
                label-cols-lg="2"
                :label="$t('TARIFFS.ALLOW_REQUEST_LOGS')"
                label-for="field-allowRequestLogs-input"
              >
                <b-form-checkbox
                  id="checkbox-allowRequestLogs-input"
                  v-model="dto.allowRequestLogs"
                  name="checkbox-allowRequestLogs"
                  class="pt-2"
                  switch
                  size="lg"
                >
                </b-form-checkbox>
              </b-form-group>

              <!--
              <b-form-group
                id="field-countries"
                label-cols-lg="2"
                :label="$t('TARIFFS.COUNTRIES')"
                label-for="field-parameters"
              >
                <multi-suggestion
                  suggestion-processor="countrySuggestionProcessor"
                  :element-name="this.$t('COUNTRIES.COUNTRY')"
                  :elements-name="this.$t('COUNTRIES.COUNTRIES')"
                  :selected-elements="selectedCountriesForSuggestion"
                  :addErrorNotification="true"
                  @on-element-added="addCountry"
                  @on-remove-element="removeCountry"
                />
              </b-form-group> -->
            </b-card-body>
          </b-tab>
        </b-tabs>

        <div style="float: right">
          <b-button variant="success" v-on:click="onSaveClicked">{{
            $t("COMMON.SAVE")
          }}</b-button>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { API_REQUEST } from "@/core/services/store/api.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
//import MultiSuggestion from "../component/MultiSuggestion";

export default {
  name: "tariff",
  components: {
    //MultiSuggestion
  },
  data() {
    return {
      save: {
        resource: "/api/tariff/save",
        requestType: "POST",
        requestParams: {},
      },

      dto: {
        id: null,
        name: null,
        description: null,
        maxRequestCount: null,
        maxUserCount: null,
        maxDeviceCount: null,
        maxManagedTeamCount: null,
        deleted: false,
        allowLogoCustomization: false,
        allowIpFiltrating: false,
        allowRequestLogs: false,
        countries: [],
      },

      selectedCountriesForSuggestion: [],
      parametersObjectName: "",
      filterParameters: "",

      showOverlay: true,
      createNewStr: this.$t("TARIFFS.CREATE_NEW_TARIFF"),
      editStr: this.$t("TARIFFS.EDIT_TARIFF"),
    };
  },

  mounted() {
    let title = !this.$route.params.id ? this.createNewStr : this.editStr;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("MENU.TARIFFS"), route: "/tariffs" },
      { title: title },
    ]);
  },

  created: async function () {
    if (this.$route.params.id != null) {
      await this.$store
        .dispatch(API_REQUEST, {
          resource: "/api/tariff/" + this.$route.params.id,
          requestType: "GET",
        })
        .then((response) => {
          this.dto = response;
          for (const parameter of this.dto.countries) {
            let country = {
              name: parameter.nameLocalized,
              id: parameter.id,
            };
            this.selectedCountriesForSuggestion.push(country);
          }
        })
        .catch(this.onError);
    }

    this.showOverlay = false;
  },

  methods: {
    onSaveClicked: function () {
      this.dto.countries.splice(0, this.dto.countries.length);
      if (this.selectedCountriesForSuggestion.length != 0) {
        for (const parameter of this.selectedCountriesForSuggestion) {
          this.dto.countries.push({ id: parameter.id });
        }
      }
      const request = this.save;
      request.dto = this.dto;

      this.$store
        .dispatch(API_REQUEST, request)
        .then((response) => {
          this.dto.id = response.id;

          this.$bvToast.toast(this.$t("COMMON.SAVED_SUCCESSFULLY"), {
            title: this.$t("COMMON.SUCCESS"),
            variant: "success",
            autoHideDelay: 5000,
          });
        })
        .catch(this.onError);
    },

    addCountry(data) {
      this.selectedCountriesForSuggestion.push(data);
    },

    removeCountry(index, data) {
      this.selectedCountriesForSuggestion.splice(index, 1);
    },

    onError: function (response) {
      if (response && response.config) response = response.data;

      this.$bvToast.toast(
        response && response.message
          ? response.message
          : this.$t("COMMON.UNKNOWN_ERROR"),
        {
          title: this.$t("COMMON.ERROR"),
          variant: "danger",
          autoHideDelay: 5000,
        }
      );
    },
  },
};
</script>
<style>
.hidden {
  display: none;
}
.form-group {
  margin-bottom: 1rem;
}
</style>
